import request from '@/utils/request'

export function list(pageNum,pageSize,datas) {
    return request({
        url: '/announcement/list?pageNum=' + pageNum + '&pageSize=' + pageSize + '&datas=' + datas, 
        method: 'get'
    })
}

export function add(data) {
    return request({
        url: '/announcement/save', 
        method: 'post',
        data
    })
}

export function edit(id,data) {
    return request({
        url: '/announcement/edit?id=' + id, 
        method: 'post',
        data
    })
}


export function del(id) {
    return request({
        url: '/announcement/delete?id=' + id, 
        method: 'get',
    })
}
